
import React, { useState, useEffect,useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FolderIcon from '@material-ui/icons/Folder';
import DeleteIcon from '@material-ui/icons/Delete';
import {SETSELECTEDDEPARTMENT} from '../store/actions/actionsDepartments';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import CallIcon from '@material-ui/icons/Call';
import VideoCallIcon from '@material-ui/icons/VideoCall';
import MessageIcon from '@material-ui/icons/Message';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import ReactTooltip from 'react-tooltip' // -- PB - 24/11/2021 

import LocationOnIcon from '@material-ui/icons/LocationOn';

import { connect } from "react-redux";

import API_URL from './GlobalVars'
import AuthService from './api-authorization/AuthorizeService'
import { setTAB } from '../store/actions/actionsUser';



var host = API_URL

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    height: "100%",

  },
  demo: {
    backgroundColor: theme.palette.background.paper,
  },
  title: {
    margin: theme.spacing(4, 0, 2),
  },
}));


function TabDepartments(props) {

  const [Dptlist, setDptlist] = React.useState(null);

  useEffect(() => { 
    refreshList();
  }, [])

  async function refreshList(){
    var url=API_URL+"/api/v1/rooms.get";
    if(AuthService.getCurrentUser()){
                var user=AuthService.getCurrentUser()
                var token=user.authToken.toString()
                var userId=user.userId.toString()
        
                fetch(url, {
                    method: 'GET', // *GET, POST, PUT, DELETE, etc.
                    // mode: 'cors', // no-cors, *cors, same-origin
                    // cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                    // credentials: 'same-origin', // include, *same-origin, omit
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Auth-Token': token,
                        'X-User-Id': userId,
                    
                    // 'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    redirect: 'follow', // manual, *follow, error
                    referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
                    // body: JSON.stringify(data) // body data type must match "Content-Type" header
                })
                .then(res => res.json())    
                .then(res =>{
                  setDptlist(res.update)
                  
                })
    };  
        
  }
    
  
  return (
    
    
      <Grid container spacing={1}>
        
        <Grid item xs style={{ backgroundColor: "#9fa1a6",maxHeight: "90%", overflow: 'hidden'}}>
 
         
            <List style={{height: "100%", overflow: 'auto'}} >
            {Dptlist?.map(channel => (
              channel.t =='p' && channel.teamMain!=true?
              <ListItem button  onClick={() => {props.SETSELECTEDDEPARTMENT(channel?.name) ; props.setTAB(1) } }>
                  <ListItemAvatar>
                    <Avatar>
                      <AccountCircleIcon />
                    </Avatar>
                  </ListItemAvatar>
                   <ListItemText  primary={channel?.name}  />
                
              
 
                  <ListItemSecondaryAction style={{top:"50%"}}>
                 
                    
                    {/*   <a target="_blank" href={host+"/group/"+channel?.name.toString()+"/video"}>
                    <IconButton  edge="end" aria-label="call">
                      <CallIcon />                
                    </IconButton>
                   
                    </a> 
                    */}
                

                    <a target="_blank" href={host+"/group/"+channel?.name.toString()+"/video"}>
                    <IconButton  edge="end" data-tip data-for="vdcall" aria-label="videocall">  {/* -- PB - 24/11/2021   data-tip data-for="vdcall"   */}
                      <VideoCallIcon />
                    </IconButton>
                    <ReactTooltip id="vdcall"  place="bottom" effect="solid" backgroundColor='#B1AD66'>  {/* -- PB - 24/11/2021 Tooltip  */}
                    Start Video Call
                  </ReactTooltip>
                    </a>
                    <a target="_blank" href={host+"/group/"+channel?.name.toString()}>  
                      <IconButton edge="end" data-tip data-for="mseg"  aria-label="message">  {/* -- PB - 24/11/2021   data-tip data-for="vdcall"   */}
                        <MessageIcon />
                      </IconButton>
                      <ReactTooltip id="mseg"  place="bottom" effect="solid" backgroundColor='#B1AD66'>  {/* -- PB - 24/11/2021 Tooltip  */}
                      Send message
                  </ReactTooltip>
                    </a>
                   
                  
                   
                  
                  </ListItemSecondaryAction>
                </ListItem>:null
                
                ))}
            </List>
             
        </Grid>
      </Grid>
  );
}


const MapStateToProps = (state) => {    
  return {    
    projects:state.checkpoints,
    selectedDepartment:state.selectedDepartment,
  };
};
const MapDispatchToProps = (dispatch) => {
  return {
    SETSELECTEDDEPARTMENT: (groups)=> dispatch(SETSELECTEDDEPARTMENT(groups)),
    setTAB: (choose) => dispatch(setTAB(choose))
  }
};
export default connect(MapStateToProps, MapDispatchToProps)(TabDepartments);