import React, { useState, useEffect } from 'react';
import { forwardRef } from 'react';
import { Grid  } from '@material-ui/core';

import { connect } from "react-redux";
import './css/Home.css';


import MaterialTable from "material-table";

import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import FolderIcon from '@material-ui/icons/Folder';
import DeleteIcon from '@material-ui/icons/Delete';

import TableProjects from './TableProjects'


import axios from 'axios'
import Alert from '@material-ui/lab/Alert';
import API_URL from './GlobalVars'
import AuthService from '../components/api-authorization/AuthorizeService'

import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import AccountCircleSharpIcon from '@material-ui/icons/AccountCircleSharp';
import SupervisedUserCircleSharpIcon from '@material-ui/icons/SupervisedUserCircleSharp';
import ApartmentSharpIcon from '@material-ui/icons/ApartmentSharp';
import LanguageSharpIcon from '@material-ui/icons/LanguageSharp';
import {withStyles} from "@material-ui/core/styles" // -- PB - 24/11/2021 
import CampaignIcon from '@mui/icons-material/Campaign'; // -- PB - 03/12/2021
const MessageTextTypography = withStyles({ // -- PB - 24/11/2021 
  root: {
    color: "#20232a"
  }
})(Typography); 

var host = API_URL
var dateFormat = require('dateformat');

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    height:"100%",
  },
  demo: {
    backgroundColor: theme.palette.background.paper,
  },
  title: {
    margin: theme.spacing(4, 0, 2),
  },
}));


function TabOperators(props) {

  const [Dptlist, setDptlist] = React.useState(null);

  useEffect(() => { 
    refreshList();
  }, [])

  async function refreshList(){
    var url=API_URL+'/api/v1/channels.history?roomId=xHmSarH9TDirSxLEP';
    if(AuthService.getCurrentUser()){
                var user=AuthService.getCurrentUser()
                var token=user.authToken.toString()
                var userId=user.userId.toString()
        
                fetch(url, {
                    method: 'GET', // *GET, POST, PUT, DELETE, etc.
                    // mode: 'cors', // no-cors, *cors, same-origin
                    // cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                    // credentials: 'same-origin', // include, *same-origin, omit
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Auth-Token': token,
                        'X-User-Id': userId,
                    
                    // 'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    redirect: 'follow', // manual, *follow, error
                    referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
                    // body: JSON.stringify(data) // body data type must match "Content-Type" header
                })
                .then(res => res.json())    
                .then(res =>{
                  setDptlist(res.messages)
                  
                })
    };  
        
  }
    

  return (
    
    
      <Grid container spacing={1}>
        
        <Grid item xs style={{maxHeight: "90%", overflow: 'hidden'}}>
 
         
            <List style={{height:"100%", overflow: 'auto'}} >
            {Dptlist?.map(channel => (
             channel.t !=='au' && channel.t!=='ru' && channel.t !=='uj' && channel.t !== 'subscription-role-added' && channel.t !== 'subscription-role-removed' && channel.t !== 'user-muted' && channel.t !== 'user-unmuted' && channel.t !== 'message_pinned' && channel.t !=='room_changed_description' && channel.t !== 'room_changed_announcement' && channel.t !== 'room_changed_topic'?
                <ListItem >
                  <ListItemAvatar>
                    <Avatar  style={{backgroundColor: "transparent"}}> 
                       <CampaignIcon style={{fill: "white"}} />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                
                     secondary={dateFormat(channel?.ts, "dddd, mmmm dS, yyyy, h:MM:ss TT")}
                     primary={<MessageTextTypography>
                    {channel?.msg}
                    </MessageTextTypography>}
                  />
                  
                </ListItem>:null
                
                ))}
            </List>
             
        </Grid>
      </Grid>
  );
}


const MapStateToProps = (state) => {    
  return {    
    projects:state.checkpoints,
  };
};
const MapDispatchToProps = (dispatch) => {
  return {
   
  }
};
export default connect(MapStateToProps, MapDispatchToProps)(TabOperators);