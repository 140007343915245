import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import './css/Home.css';
import '../App.css';
import  MapContainer  from './Map/MapContainer';

import { Button } from 'react-bootstrap';
import TableProjects from './TableProjects'

import TableUsrs from './TableUsrs'

import ResizePanel from "react-resize-panel";



class MainMapGK extends Component {
	constructor(props) {
    super(props);
  }
  state = {
    isActive: true
  };

  handleShow = () => {
    this.setState({isActive: true});
  };

  handleHide = () => {
    this.setState({isActive: false});
  };
  render () {

    return (
      <div className="containerHome">
 <div class="container">

 
            <div class="row">
              <div class="col">
              <TableUsrs/>
              </div>
              <div class="col">
              
              <ResizePanel direction="w"  style={{  flexGrow: '1',width:"35%",height:"200px"}} >
                      <div className="sidebar withMargin panel">
                    
                      <MapContainer  />
                      
                      </div>
                      </ResizePanel>
              </div>
              <div class="col">
              <TableUsrs/>
              </div>
            </div>
  <div class="row">
              <div class="col">
              <TableProjects/>
              </div>
              <div class="col">
              
              <ResizePanel direction="w"  style={{ flexGrow: '1',width:"35%",height:"200px"}} >
                      <div className="sidebar withMargin panel">
                    
                      <MapContainer  />
                      
                      </div>
                      </ResizePanel>
              </div>
              <div class="col">
              <TableProjects/>
              </div>
            </div>




          </div>
            
              

    
      </div>
    );
  }
}
export const MapGK = (MainMapGK);