
import API_URL from '../../components/GlobalVars'
import AuthService from '../../components/api-authorization/AuthorizeService'
import { Alert } from 'react-bootstrap';



var host = API_URL


export const FETCH_CHECKPOINTS = "FETCH_CHECKPOINTS";
 
let  generalObj = [];
export const fetchCheckpoints = (dispatch) => {  
    
var url=API_URL+"/api/v1/users.list?fields={ \"customFields\": 1 }";
if(AuthService.getCurrentUser()){
    
            var user=AuthService.getCurrentUser()
            var token=user?.authToken?.toString()
            var userId=user?.userId?.toString()
            if(userId){
                fetch(url, {
                    method: 'GET', // *GET, POST, PUT, DELETE, etc.
                    // mode: 'cors', // no-cors, *cors, same-origin
                    // cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                    // credentials: 'same-origin', // include, *same-origin, omit
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Auth-Token': token,
                        'X-User-Id': userId,
                    
                    // 'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    redirect: 'follow', // manual, *follow, error
                    referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
                    // body: JSON.stringify(data) // body data type must match "Content-Type" header
                })
                .then(res => res.json())
                .then(res => generalObj=res)     
                .then(res => dispatch({
                    type:FETCH_CHECKPOINTS,payload:res.users
                }))
            }
            return null;
    };  
    
}

    export const clearCheckPointMap = (dispatch) => {
        dispatch({
            type: FETCH_CHECKPOINTS,
            payload:[]
        })

    };
// -- * PB - 17/11/2021 TO DO 
export const  GetAllUsr = (dispatch) => {
   
    //alert("I am in getallusr");
    var url=API_URL+"/api/v1/users.list";
    if(AuthService.getCurrentUser()){
        
                var user=AuthService.getCurrentUser()
                var token=user?.authToken?.toString()
                var userId=user?.userId?.toString()
 
                if(userId){
                    fetch(url, {
                        method: 'GET', // *GET, POST, PUT, DELETE, etc.
                        // mode: 'cors', // no-cors, *cors, same-origin
                        // cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                        // credentials: 'same-origin', // include, *same-origin, omit
                        headers: {
                            'Content-Type': 'application/json',
                            'X-Auth-Token': token,
                            'X-User-Id': userId,
                        
                        // 'Content-Type': 'application/x-www-form-urlencoded',
                        },
                        redirect: 'follow', // manual, *follow, error
                        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
                      
                    })
                    .then(data => data.json())
                   // .then(data => console.log(data.users[2]._id) ) 
                    .then(data => idd(data) )
                    .then(console.log("Deleted all Users"))                   
                    .then(delCheckPoint())                    
                    .then(data => dispatch({
                        type:FETCH_CHECKPOINTS,payload:generalObj.users// generalObj.users.map((user)=> user.customFields={})
                    }))
                    .then(console.log(generalObj.users.name))
                    
                     
                }
        
            } 
            return null;
        };  


    let text=[];
// -- PB - 17/11/2021
function idd(data){

    for (let i = 0; i < data.count; i++) {
        text.push(data.users[i]._id) ;
      }
       
    //  alert(text.length);
}

export const downloadTxtFile = (dispatch) => { // -- PB -- 05/04/2022
    const element = document.createElement("a");
    const obj = {hello: 'world'};

    var url=API_URL+"/api/v1/users.list";
    if(AuthService.getCurrentUser()){
        
                var user=AuthService.getCurrentUser()
                var token=user?.authToken?.toString()
                var userId=user?.userId?.toString()
 
                if(userId){
                    fetch(url, {
                        method: 'GET', // *GET, POST, PUT, DELETE, etc.
                        // mode: 'cors', // no-cors, *cors, same-origin
                        // cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                        // credentials: 'same-origin', // include, *same-origin, omit
                        headers: {
                            'Content-Type': 'application/json',
                            'X-Auth-Token': token,
                            'X-User-Id': userId,
                        
                        // 'Content-Type': 'application/x-www-form-urlencoded',
                        },
                        redirect: 'follow', // manual, *follow, error
                        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
                      
                    })
                    .then(data => data.json())
                   // .then(data => console.log(data.users[2]._id) ) 
                    .then(data => idd(data) )                                     
                    .then(data => dispatch({
                        type:FETCH_CHECKPOINTS,payload:generalObj.users// generalObj.users.map((user)=> user.customFields={})
                    }))
                    .then(console.log(generalObj.users.name))
                    
                     
                }
        
            }

    var csvContent = "data:text/csv;charset=utf-8,";

    


            const results=[]; 
            results.push("Username");
            csvContent += "Username";
            csvContent += "\;";
            csvContent += "lat";
            csvContent += "\;";
            csvContent += "lng";
            csvContent += "\;";
            csvContent += "\n";
        //    results.push('\t');
            results.push("lat");
        //    results.push('\t');
            results.push("lng");
            results.push('\n');
            generalObj.users.forEach(element => {
                if(element.username!="rocket.cat" && element.username!="xbello-notification.bot" && element.username!="xbelloplugin.bot"){
                    csvContent += element.username;
                    csvContent += "\;";
                    results.push(element.username);
               results.push(element.customFields?.lat);
               csvContent += element.customFields?.lat ;
               csvContent += "\;";
                    results.push(element.customFields?.lng);
                    csvContent += element.customFields?.lng;
                    csvContent += "\n";
            }
            });
   
          //  [JSON.stringify(generalObj, null, 2)]
    // const file = new Blob(results, {type: 'text/plain'});
    // element.href = URL.createObjectURL(file);
    // element.download = "UsersLocation.txt";
    // document.body.appendChild(element); // Required for this to work in FireFox
    // element.click();




    var encodedUri = encodeURI(csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "UsersLocation.csv");
    document.body.appendChild(link); // Required for FF
    link.click();
    document.body.removeChild(link); 
    

  }





// -- * PB - 11/11/2021 TO DO 

export const delCheckPoint = (dispatch) => {  
 //alert("I am in delcheckPoint");
    for (let i = 0; i < text.length; i++) {
        //alert(text[i]);
        var url=API_URL+"/api/v1/users.update?data.customFields";
        if(AuthService.getCurrentUser()){
                    var user=AuthService.getCurrentUser()
                    var token=user.authToken.toString()
                    var userId=user.userId.toString()

                fetch(url, {
                    method: 'POST', // *GET, POST, PUT, DELETE, etc.
                    // mode: 'cors', // no-cors, *cors, same-origin
                    // cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                    // credentials: 'same-origin', // include, *same-origin, omit
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Auth-Token': token,
                        'X-User-Id': userId,
                    
                    // 'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    body: JSON.stringify({ 
                        userId: text[i], 
                         data: {
                        customFields:
                        {
                            "lat":"",
                            "lng":""
                        }
                      }, }),
                  
                    redirect: 'follow', // manual, *follow, error
                    referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
                    // body: JSON.stringify(data) // body data type must match "Content-Type" header
                })
                .then(res => res.json())    
            
            }
        }
            return null;
    };  


    
    



    



