import {SET_CENTER_LAT} from '../actions/actionsUser';
;

const latReducer = (state = {}, {type, payload}) => {
    switch(type) {        
        case SET_CENTER_LAT :       
            return payload   

        default :    
            return state
    };
};
export default latReducer;
