import React, { Component } from 'react';
import { MarkerClusterer,Marker  ,InfoWindow} from '@react-google-maps/api';
import axios from 'axios';
import { Button } from 'react-bootstrap';

import { connect } from "react-redux";






import API_URL from '../GlobalVars'


var host = API_URL



  
  const options = {
    imagePath:
      'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m', // so you must have m1.png, m2.png, m3.png, m4.png, m5.png and m6.png in that folder
  }
  
  

  class CheckpointMarker extends Component{

    constructor(props){
        super(props);
        this.state = {
          showingInfoWindow:null,
        }
    }
    handleToggleOpen = (markerid) => {

        this.setState({
            showingInfoWindow: markerid
        });
    }

    handleToggleClose = () => {
        this.setState({
            showingInfoWindow: null
        });
    }

    componentDidMount(){

       
    }

    
    handleChange = param => e => {
      // param is the argument you passed to the function
      // e is the event object that returned
      console.log(param)
      console.log(e)

  };
 


    onLoad = (currentMarker) => {
      

      var iconLink = "";

      var title=currentMarker.title==='undefined'?'':currentMarker.title
    
  
    
             
     


    };

   InfoWindowDetails() {

   const currentMarker=this.state.showingInfoWindow
 


   
    return  <React.Fragment>
         <div>
          {currentMarker.username} 
          <br />
        </div>
      </React.Fragment>
    
    }




    
  
    
    render(){


    
        return(
         
         
          this.props.checkpoints.map((marker) => (
              <Marker 
              key={(marker._id)}
         onClick={() => this.handleToggleOpen(marker)}
              onLoad={this.onLoad}
              options={{
                title:marker.username,
                id:marker._id,
                typeID:"",
                
              }
              }
              position={ { lat: marker.customFields? parseFloat(marker.customFields.lat): null , lng: marker.customFields? parseFloat(marker.customFields.lng): null  }} 
              
              >
              {this.state.showingInfoWindow==(marker) &&
             <InfoWindow onCloseClick={this.handleToggleClose}>
                   {this.InfoWindowDetails()}
             </InfoWindow>
            }
              
        </Marker>
            ))
          
           
    
          
        )
        
    }


}
const MapStateToProps = (state) => {    
  return {    

    checkpoints: state.checkpoints,
	
  };
};
const MapDispatchToProps = (dispatch) => {
  return {
  }
};
export default connect(MapStateToProps, MapDispatchToProps)(CheckpointMarker);


//export default CustomMarker;