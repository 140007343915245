
import API_URL from '../../components/GlobalVars'
import AuthService from '../../components/api-authorization/AuthorizeService'



var host = API_URL

export const SET_SELECTED_DEPARTMENT= "SET_SELECTED_DEPARTMENT";

export const FETCH_CHECKPOINTS = "FETCH_CHECKPOINTS";

export const SETSELECTEDDEPARTMENT= (groups) => async dispatch => {  
    dispatch({
        type:SET_SELECTED_DEPARTMENT,
        payload:groups
    })
};

export const fetchCheckpoints = (dispatch) => {  
    
var url=API_URL+"/api/v1/groups.list?fields={ \"customFields\": 1 }";
if(AuthService.getCurrentUser()){
            var user=AuthService.getCurrentUser()
            var token=user.authToken.toString()
            var userId=user.userId.toString()
    
            fetch(url, {
                method: 'GET', // *GET, POST, PUT, DELETE, etc.
                // mode: 'cors', // no-cors, *cors, same-origin
                // cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                // credentials: 'same-origin', // include, *same-origin, omit
                headers: {
                    'Content-Type': 'application/json',
                    'X-Auth-Token': token,
                    'X-User-Id': userId,
                
                // 'Content-Type': 'application/x-www-form-urlencoded',
                },
                redirect: 'follow', // manual, *follow, error
                referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
                // body: JSON.stringify(data) // body data type must match "Content-Type" header
            })
            .then(res => res.json())    
            .then(res => dispatch({
                type:FETCH_CHECKPOINTS,payload:res.groups
            }))
    };  
    
}

