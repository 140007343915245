import React, { useState, useEffect } from 'react';
import { forwardRef } from 'react';
import { Grid  } from '@material-ui/core';

import { connect } from "react-redux";
import './css/Home.css';


import MaterialTable from "material-table";

import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import FolderIcon from '@material-ui/icons/Folder';
import DeleteIcon from '@material-ui/icons/Delete';

import TableProjects from './TableProjects'


import axios from 'axios'
import Alert from '@material-ui/lab/Alert';
import API_URL from './GlobalVars'
import AuthService from '../components/api-authorization/AuthorizeService'

import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import AccountCircleSharpIcon from '@material-ui/icons/AccountCircleSharp';
import SupervisedUserCircleSharpIcon from '@material-ui/icons/SupervisedUserCircleSharp';
import ApartmentSharpIcon from '@material-ui/icons/ApartmentSharp';
import LanguageSharpIcon from '@material-ui/icons/LanguageSharp';


import TabOperators from './TabOperators';
import TabTeams from './TabTeams';
import TabDepartments from './TabDepartmentsInfo';
import TabGlobal from './TabGlobal';



function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

 

var host = API_URL

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),

};

const token='Bearer '+AuthService.getCurrentUser()?.authToken.toString()

const api = axios.create({
  baseURL: host,
  headers: {
    'Authorization': token
  }
})


function validateEmail(email){
  const re = /^((?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\]))$/;
  return re.test(String(email).toLowerCase());
}

function TableInformationCh(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [open, setOpen] = React.useState(false);
  const [AddFirstResponderName, setAddFirstResponderName] = React.useState(null);
  const [AddFirstResponderEmail, setAddFirstResponderEmail] = React.useState(null);
  const [AddFirstResponderPassword, setAddFirstResponderPassword] = React.useState(null);


  const handleInputChangeAddFirstResponderName = e => {
    setAddFirstResponderName(e.target.value);
};

const handleInputChangeAddFirstResponderEmail = e => {
  setAddFirstResponderEmail(e.target.value);
};

const handleInputChangeAddFirstResponderPassword = e => {
  setAddFirstResponderPassword(e.target.value);
};

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setAddFirstResponderName(null);
    setAddFirstResponderEmail(null);
    setAddFirstResponderPassword(null);

  };

  const handleCloseAddFirstResponder = () => {
   // alert(AddFirstResponderName+','+AddFirstResponderEmail+','+AddFirstResponderPassword)

    let errorList = []
    if(AddFirstResponderName === undefined){
      errorList.push("Please enter FirstRespondername")
    }
    if(AddFirstResponderPassword === undefined){
      errorList.push("Please enter password")
    }
    if(AddFirstResponderEmail === undefined || validateEmail(AddFirstResponderEmail) === false){
      errorList.push("Please enter a valid email")
    }
    var data={
      FirstRespondername:AddFirstResponderName,
        Email:AddFirstResponderEmail,
        Password:AddFirstResponderPassword,
    }
   // alert(data.FirstRespondername)

    if(errorList.length < 1){ //no error
      // api.post("/api/Authenticate/register", data)
      // .then(res => {
      //   setErrorMessages([])
      //   setIserror(false)
      // })
      // .catch(error => {
      //   setErrorMessages(["Cannot add data. Server error!"])
      //   setIserror(true)
      // })
      api({
        method: 'post',
        url:  "/api/Projects/",
        data: {
          FirstRespondername: AddFirstResponderName,
          password: AddFirstResponderPassword,
          email:AddFirstResponderEmail,
        },
        headers: {
          'content-type': 'application/json'
        }
      }).then(response => {
        setErrorMessages([])
         setIserror(false)
        // refreshProjects()
            // if (response.data.token) {
            //   localStorage.setItem("FirstResponder", JSON.stringify(response.data));
            // }
    
            // return response.data;
          })  .catch(error => {
              setErrorMessages(["Cannot add data. Server error!"])
              setIserror(true)
             });
    }else{
      setErrorMessages(errorList)
      setIserror(true)
    }



    setOpen(false);
  };

  const [data, setData] = useState([]); //table data

  //for error handling
  const [iserror, setIserror] = useState(false)
  const [errorMessages, setErrorMessages] = useState([])




  return (
 
      
      <Grid container spacing={1}>
        
        <AppBar position="static">
  <Tabs
   value={value} 
   onChange={handleChange} 
   aria-label="simple tabs example"
   variant="fullWidth"
   centered
   >
    <Tab label="Operators" icon={<AccountCircleSharpIcon />} {...a11yProps(0)} />
    <Tab label="Teams" icon={<SupervisedUserCircleSharpIcon />}{...a11yProps(1)} />
    <Tab label="Departments" icon={<ApartmentSharpIcon />}{...a11yProps(2)} />
    <Tab label="Maintenance / Damage Control" icon={<LanguageSharpIcon />}{...a11yProps(3)} />
  </Tabs>
</AppBar>



<TabPanel value={value} style={{backgroundColor: "#9fa1a6" ,width:"100%",height:"100%"}} index={0}>
  <TabOperators/>
</TabPanel>
<TabPanel value={value}  style={{backgroundColor: "#9fa1a6" ,width:"100%",height:"100%"}} index={1}>
  <TabTeams/>
</TabPanel>
<TabPanel value={value}  style={{backgroundColor: "#9fa1a6" ,width:"100%",height:"100%"}} index={2}>
  <TabDepartments/>
</TabPanel>
<TabPanel value={value} style={{backgroundColor: "#9fa1a6" ,width:"100%",height:"100%"}} index={3}>
  <TabGlobal/>
</TabPanel>
         
        </Grid>

     

     

     

  );
}


  export default TableInformationCh;