import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import './i18n';
import App from './App';
//import registerServiceWorker from './registerServiceWorker';
import GlobalStyles from './styles/globals';
import store from './store/index'
import {Provider} from 'react-redux'


const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

ReactDOM.render(
  <BrowserRouter basename={baseUrl}>
      <Provider store={store}>

              <App />
        
      </Provider>
  </BrowserRouter>, 
  rootElement
);


// const renderApp = () => {
//   // const initialState = loadInitialState();
//   // const store = configureStore(initialState);

 
  
//   ReactDOM.render(
//     // <Provider store={store}>
//     <BrowserRouter basename={baseUrl}>
//     <div>
//         <GlobalStyles />
//         <App />
//        </div>
//     </BrowserRouter>,
//     // </Provider>,
//     rootElement);

 


// };

// renderApp();










// Uncomment the line above that imports the registerServiceWorker function
// and the line below to register the generated service worker.
// By default create-react-app includes a service worker to improve the
// performance of the application by caching static assets. This service
// worker can interfere with the Identity UI, so it is
// disabled by default when Identity is being used.
//
//registerServiceWorker();

