import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { NavMenu } from './NavMenu';
import { connect } from "react-redux";

 class Layout extends Component {
  static displayName = Layout.name;
  
  constructor(props) {
		super(props);
  }
  componentDidMount(){
    //this.props.fetchUsers();
 }
  render () {
    return (
      <div>
        <NavMenu />
        <Container>
          {this.props.children}
        </Container>
      
       
      </div>
    );
  }
}

export default Layout;