import React, { Component } from 'react';
import { Route } from 'react-router';
import  Layout  from './components/Layout';
import  Home  from './components/Home';
import { Login } from './components/api-authorization/Login';
import { MapGK } from './components/MainMapGK';



import axios from 'axios';
import { connect } from "react-redux";

import {fetchCheckpoints} from './store/actions/actionsCheckpoints'; 

import {SETLAT,SETLNG} from './store/actions/actionsUser';


import AuthorizeRoute from './components/api-authorization/AuthorizeRoute';

import './custom.css'
import API_URL from './components/GlobalVars'



var host = API_URL

 class App extends Component {
	 
	 	static displayName = App.name;

	 
	constructor(props) {
		super(props);
  
		
	}
	
	componentDidMount() {
				 this.props.fetchCheckpoints();
			


	  }
	
	render() {
		return (
			<Layout>
				
				<Route exact path='/' component={Home} />
				<Route path='/login' component={Login} />

		<AuthorizeRoute path='/operation' component={MapGK} />

				{/* <AuthorizeRoute path='/fetch-data' component={FetchData} />
				<AuthorizeRoute path='/users' component={UsersRouter} /> */}
			
			</Layout>
		);
	}
}
const MapStateToProps = (state) => {    
    return {    

    };
  };
const MapDispatchToProps = (dispatch) => {
	return {
		fetchCheckpoints: ()=> dispatch(fetchCheckpoints),

		SETLAT: (lat)=> dispatch(SETLAT(lat)),
	
		SETLNG: (lng)=> dispatch(SETLAT(lng)),

	}
  };
  export default connect(MapStateToProps, MapDispatchToProps)(App);



