import React, { Component } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';
import { LanguageSelect } from './LanguageSelect';
import { withTranslation } from 'react-i18next';
import AuthService from './api-authorization/AuthorizeService'

import MoreVertIcon from '@material-ui/icons/MoreVert';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import WbSunnyIcon from '@material-ui/icons/WbSunny';
import axios from 'axios'
class NavMenuPlain extends Component {
	static displayName = NavMenuPlain.name;

	constructor(props) {
		super(props);
		this.logOut = this.logOut.bind(this);
		this.toggleNavbar = this.toggleNavbar.bind(this);
		this.state = {
			currentUser: undefined,
			collapsed: true,
			weathers:'',
			countryName: '',
			countryCode: ''

		};
	}

	componentDidMount() {
		this.refreshList();
		const user = AuthService.getCurrentUser();
		//console.log(user.me.name)
		if (user) {
		  this.setState({
			currentUser: user,
		  });
		}
		this.getGeoInfo();
		
		   setInterval(() => {
      this.setState({
        curTime : new Date().toLocaleString()
      })
    }, 1000)
	  }
	
	  logOut() {
		AuthService.logout();
	  }

	// componentDidMount() {
	// 	this._subscription = authService.subscribe(() => this.populateState());
	// 	this.populateState();
	// }

	// async populateState() {
	// 	const hasAdminRole = await authService.hasRole(UserRoles.Administrator);
	// 	this.setState({
	// 		hasAdminRole
	// 	});
	// }
	
	getGeoInfo = () => {
    axios.get('https://ipapi.co/json/').then((response) => {
        let data = response.data;
        this.setState({
            countryName: data.city,
            countryCode: data.country_code
        });
    }).catch((error) => {
        console.log(error);
    });
};
	
	async refreshList(){
        let response = await fetch("http://api.weatherapi.com/v1/current.json?key=5abe1457cd0c4719bc0104333212803&q=38.040197%2C23.675783");
        let data = await response.json();
        
        let weatherText =   data.current?.temp_c  +"°C "
               
        this.setState({
            weathers:weatherText
        })
    }
	toggleNavbar() {
		this.setState({
			collapsed: !this.state.collapsed
		});
	}

	render() {
		const { t, i18n } = this.props;
		const { currentUser } = this.state;
		const d = Date(Date.now());
		const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
		
		var dateFormat = require('dateformat');
var now = new Date();
	
		return (
			<header>
				<Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom" style={{backgroundColor:"#2F343D"}}>
				
					<img src="/logo.png" style={{width:"8%",height:"8%",paddingRight:"10px"}}/>
						<NavbarBrand tag={Link} to="/" style={{color:"white",paddingRight:"20px"}}>XBELLO - Operational Administration</NavbarBrand>
						<AccessTimeIcon style={{color: "white", paddingRight:"10px"}}/>
						<div style={{color: "white",paddingRight:"20px"}}>{dateFormat(now, "dddd, mmmm dS, yyyy, HH:MM:ss ")}</div>
						<WbSunnyIcon style={{color: "white",paddingRight:"10px"}}/>
						<div style={{color: "white"}}>{this.state.weathers}</div>
						<div style={{color: "white",paddingLeft:"10px"}}>{
						<div >{this.state.countryName+","}</div>
						}</div>
						
						<div style={{color: "white",paddingLeft:"5px"}}>{
						<div>{this.state.countryCode}</div>
						}</div>
						
						
						<NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
						<Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar>
							<ul className="navbar-nav flex-grow">
							
							
							
							
							
		{currentUser ? (
            <div className="navbar-nav ml-auto">
             <NavItem>
               <Link  className="nav-link"> 
                  {currentUser.me?.name}
             </Link> 
             </NavItem>
			
		
			 <NavItem>
                <a href="/login" className="nav-link" onClick={this.logOut}>
                  LogOut
                </a>
              </NavItem>
			 
							
            </div>
          ) : (
               <NavItem>
                <Link to={"/login"} className="nav-link">
                  Login
                </Link>
               </NavItem>
          )}
		  <MoreVertIcon style={{marginTop:"10%"}}/>
		
									{/* <NavItem>
									<NavLink tag={Link} className="text-dark" to="/fetch-data">{t('Fetch data')}</NavLink>
								</NavItem>
								{
									this.state.hasAdminRole &&
									<NavItem>
										<NavLink tag={Link} className="text-dark" to="/users">{t('Users')}</NavLink>
									</NavItem>
								} */}
							
								{/* <LanguageSelect>
								</LanguageSelect> */}
								
							</ul>
						</Collapse>
					
				</Navbar>
			</header>
		);
	}
}

export const NavMenu = withTranslation()(NavMenuPlain);