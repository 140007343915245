
import React,{useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FolderIcon from '@material-ui/icons/Folder';
import DeleteIcon from '@material-ui/icons/Delete';
import {SETSELECTEDUSER,SETLAT,SETLNG} from '../store/actions/actionsUser';

import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import CallIcon from '@material-ui/icons/Call';
import VideoCallIcon from '@material-ui/icons/VideoCall';
import MessageIcon from '@material-ui/icons/Message';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';

import LocationSearchingIcon from '@material-ui/icons/LocationSearching';

import LocationOnIcon from '@material-ui/icons/LocationOn';
import ReactTooltip from 'react-tooltip' // -- PB - 24/11/2021 
import { connect } from "react-redux";

import API_URL from './GlobalVars'
import AuthService from '../components/api-authorization/AuthorizeService'
import { SET_SELECTED_USER } from '../store/actions/actionsUser';
import { setTAB } from '../store/actions/actionsUser';



var host = API_URL
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    height: "100%",
  },
  demo: {
    backgroundColor: theme.palette.background.paper,
  },
  title: {
    margin: theme.spacing(4, 0, 2),
  },
}));


const User = ({ name }) => (

  <div>

  

      <p>{name}</p>

      
  </div>

);



function InteractiveList(props) {
  const [UserList, setUserList] = React.useState(null);

  useEffect(() => { 
   
  }, [])

  async function refreshList(){

    
                  
  

      
     

    
    
     // return userList;
        
  }

  const getArea = (user) => { 
    //alert(user?.username.toString());
    
    var area='';
    var area1='';  
   
    //console.log(user)
    var lat= user?.customFields? parseFloat(user?.customFields.lat): null 
    var lng= user?.customFields? parseFloat(user?.customFields.lng): null 
    //alert(lat+","+lng);
   
    if(lat){
      const geocoder = new window.google.maps.Geocoder();
      const latlng = {
        lat: lat,
        lng: lng,
      };
  
      geocoder
      .geocode({ location: latlng })
      .then((response) => {
        if (response.results[0]) {
         
          area=response.results[0]?.address_components[2]?.short_name;
          
          area1 = response.results[0]?.address_components[5]?.long_name; // -- PB - 16/11/2021
          var hasNumber = /\d/;          // -- PB - 16/11/2021
          if(hasNumber.test(area1)){
            area1 = response.results[0]?.address_components[4]?.long_name; // -- PB - 16/11/2021
          }
         // alert(area1);
         var element = document.getElementById("test_"+user.username.toString());
         element.innerText=area+" , "+area1 // -- PB - 15/11/2021 

        } else {
          window.alert("No results found");
        }
      });
      //.catch((e) => window.alert("Geocoder failed due to: " + e)); // -- PB - 11/10/2021 Krybo to alert pou petaei
    }
  
    
  }

  const getPosition = (user) => { 
    //alert(user?.username.toString());
 

    //console.log(user)
    var lat= user?.customFields? parseFloat(user?.customFields.lat): null 
    var lng= user?.customFields? parseFloat(user?.customFields.lng): null 
    //alert(lat+","+lng);
    if(lat){
      props.SETLAT(lat)
      props.SETLNG(lng)
    }
  }

  const getUri = (name) => { 
    var url=API_URL+'/api/v1/im.create';

    //alert(username)
    if(AuthService.getCurrentUser()){
                var user=AuthService.getCurrentUser()
                var token=user.authToken.toString()
                var userId=user.userId.toString()
                var data={
                   "username": name // -- PB - 10/11/2021 SOS Exoume allaksei to name se username, kai mono me to username to briksei!
                }
                fetch(url, {
                    method: 'POST', // *GET, POST, PUT, DELETE, etc.
                    // mode: 'cors', // no-cors, *cors, same-origin
                    // cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                    // credentials: 'same-origin', // include, *same-origin, omit
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Auth-Token': token,
                        'X-User-Id': userId,
                    
                    // 'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    redirect: 'follow', // manual, *follow, error
                    referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
                    body: JSON.stringify(data) // body data type must match "Content-Type" header
                })
                .then(res => res.json())    
                .then(res =>{
                  var url2=API_URL+"/direct/"+res.room.rid+"/video";
                  window.open(url2, '_blank');
                  
                })

                
    };
      
  }
 
  return (
    
    
      <Grid container spacing={1}>
          
        <Grid item xs style={{maxHeight: "90%", overflow: 'hidden'}}>
  
            <List style={{height: "100%", overflow: 'auto', right: "20px"}} >
          
            {props.projects.map(user => (
              
              user.name != "Alert" && user.name != "Alert" && user.name != "XbelloPlugin" && user.name!="Rocket.Cat" && user.name != "Xbello.Cat"?
                <ListItem>
                 
                
                  <ListItem button  onClick={() =>  { props.SETSELECTEDUSER(user.username); props.setTAB(0)}  }>
                  <Avatar>
                      <AccountCircleIcon />
                    </Avatar>
                   <ListItemText inset primary={user.name} style={{position:"absolute"}} /> {/* -- PB - 11/11/2021 style={{position:"absolute"}} */}
                    </ListItem>            

                  <ListItemSecondaryAction style={{top:"50%" }}>
                 
                      {/* <LocationOnIcon style={{color:"red"}} />  */}
                     <label id={"test_"+user.username.toString()} style={{fontSize: "13px", marginRight: "-15px"}}>   {getArea(user) }</label>
                     <IconButton edge="end" aria-label="search">
                      <LocationOnIcon style={{color:"red"}} />
                    </IconButton>
                 
                   
                     {/*     
                     <a target="_blank" onClick={() => getUri(user?.username.toString())}>
                    <IconButton edge="end" aria-label="call">
                      <CallIcon />
                    </IconButton>
                    </a>
                     */} {/* -- PB - 15/11/2021 */}

                    {/* <button onClick={() => getUri(user?.username.toString())}>
                    <IconButton edge="end" aria-label="call">
                      <CallIcon />
                    </IconButton>
                    </button> */}
       
                    <a target="_blank" data-tip='tooltip' onClick={() => getUri(user?.username.toString())  }>
           
                    <IconButton  data-tip data-for="vcall" edge="end" aria-label="videocall"> {/* -- PB - 24/11/2021   data-tip data-for="vcall"  */}

                      <VideoCallIcon />
                    </IconButton>
                    <ReactTooltip id="vcall"  place="bottom" effect="solid" backgroundColor='#B1AD66'>  {/* -- PB - 24/11/2021 Tooltip  */}
                    Start Video Call
                  </ReactTooltip>
                    </a>

                    <a target="_blank" href={host+"/direct/"+user?.username.toString()}>
                      <IconButton data-tip data-for="mes" edge="end" aria-label="message">{/* -- PB - 24/11/2021    data-tip data-for="mes"  */}
                        <MessageIcon />
                        <ReactTooltip id="mes"  place="bottom" effect="solid" backgroundColor='#B1AD66' >  {/* -- PB - 24/11/2021 Tooltip  */}
                    Send message
                  </ReactTooltip>
                      </IconButton>
                    </a>
                   {/*
                    <IconButton edge="end" aria-label="alarm">
                      <NotificationsActiveIcon style={{color:"red"}} />
                    </IconButton>
                   */} {/* -- PB - 15/11/2021 */}
                  
                    <IconButton data-tip data-for="loc" onClick={() => getPosition(user)} edge="end" aria-label="search"> {/* -- PB - 24/11/2021   data-tip data-for="loc"  */}
                      <LocationSearchingIcon />
                      <ReactTooltip id="loc"  place="bottom" effect="solid" backgroundColor='#B1AD66'>  {/* -- PB - 24/11/2021 Tooltip  */}
                    Find location
                  </ReactTooltip>
                    </IconButton>
                    

                  </ListItemSecondaryAction>
                </ListItem>:null
                
                ))}
            </List>
             
        </Grid>
      </Grid>
  );
}


const MapStateToProps = (state) => {    
  return {    
    projects:state.checkpoints,
    selectedUser:state.selectedUser,
    selectedTab:state.selectedTab
  };
};
const MapDispatchToProps = (dispatch) => {
  return {
    
    SETSELECTEDUSER: (user)=> dispatch(SETSELECTEDUSER(user)),
    SETLAT: (lat)=> dispatch(SETLAT(lat)),

		SETLNG: (lng)=> dispatch(SETLNG(lng)),
    setTAB: (choose) => dispatch(setTAB(choose)),

  }
};
export default connect(MapStateToProps, MapDispatchToProps)(InteractiveList);