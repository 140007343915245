
import API_URL from '../../components/GlobalVars'
import AuthService from '../../components/api-authorization/AuthorizeService'
import axios from 'axios'


var host = API_URL

export const SET_SELECTED_USER = "SET_SELECTED_USER";

export const SET_CENTER_LAT = "SET_CENTER_LAT";

export const SET_CENTER_LNG = "SET_CENTER_LNG";


export const FETCH_CHECKPOINTS = "FETCH_CHECKPOINTS";

 export const SELECTED_TAB = "SELECTED_TAB";

export const DEL_POTITIONS = (user) => async dispatch => {  
    dispatch({
        type:FETCH_CHECKPOINTS,
        payload:[]
    })
};

export const SETSELECTEDUSER = (user) => async dispatch => {  
    dispatch({
        type:SET_SELECTED_USER,
        payload:user
    })
};

export const SETLAT = (lat) => async dispatch => {  
    dispatch({
        type:SET_CENTER_LAT,
        payload:lat
    })
};

export const SETLNG = (lng) => async dispatch => {  
    dispatch({
        type:SET_CENTER_LNG,
        payload:lng
    })
};

export const setTAB = (choose) => async dispatch => {
    dispatch({
            type: SELECTED_TAB,
            payload:choose
        })
};

 
 

// -- PB - 02/12/2021
export const SETMAPINIT = () => async dispatch =>{
   const getGeoInfo = () => { 
        axios.get('https://ipapi.co/json/').then((response) => {
            let data = response.data;

            dispatch({
                type:SET_CENTER_LAT,
                payload:data.latitude
            })

            dispatch({
                type:SET_CENTER_LNG,
                payload:data.longitude
            })
 
        }).catch((error) => {
            console.log(error);
        });
    };

    getGeoInfo();
}

export const fetchCheckpoints = (dispatch) => {  
    
var url=API_URL+"/api/v1/users.list?fields={ \"customFields\": 1 }";
if(AuthService.getCurrentUser()){
            var user=AuthService.getCurrentUser()
            var token=user.authToken.toString()
            var userId=user.userId.toString()
    
            fetch(url, {
                method: 'GET', // *GET, POST, PUT, DELETE, etc.
                // mode: 'cors', // no-cors, *cors, same-origin
                // cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                // credentials: 'same-origin', // include, *same-origin, omit
                headers: {
                    'Content-Type': 'application/json',
                    'X-Auth-Token': token,
                    'X-User-Id': userId,
                
                // 'Content-Type': 'application/x-www-form-urlencoded',
                },
                redirect: 'follow', // manual, *follow, error
                referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
                // body: JSON.stringify(data) // body data type must match "Content-Type" header
            })
            .then(res => res.json())    
            .then(res => dispatch({
                type:FETCH_CHECKPOINTS,payload:res.users
            }))
    };  
    
}


 