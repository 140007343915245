import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import '../App.css';

import './css/Home.css';
import '../App.css';
import  MapContainer  from './Map/MapContainer';

import { Button } from 'react-bootstrap';
import TableProjects from './TableProjects'
import ResizePanel from "react-resize-panel";

import TableUsrs from './TableUsrs'
import TableAlerts from './TableAlerts'
import TableReports from './TableReports'
import TableMessages from './TableMessages'
import TableInformationCh from './TableInformationCh'

import ReactFullscreen from 'react-easyfullscreen';

import ZoomOutMapIcon from '@material-ui/icons/ZoomOutMap';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import IconButton from '@material-ui/core/IconButton';
import { connect } from "react-redux";

import {fetchCheckpoints, GetAllUsr, downloadTxtFile} from '../store/actions/actionsCheckpoints'; 
import TabAlerts  from '../components/TabAlerts';
 

import RefreshIcon from '@material-ui/icons/Refresh';
import {DEL_POTITIONS} from '../store/actions/actionsUser'; 
import {clearCheckPointMap} from '../store/actions/actionsCheckpoints';



import { Grid, Icon  } from '@material-ui/core';
import Delete from '@material-ui/icons/Delete';
import DownloadIcon from '@mui/icons-material/Download';

import {delCheckPoint} from '../store/actions/actionsCheckpoints'; // -- PB- 12/11/2021



class HomeMain extends Component {
  static displayName = HomeMain.name;
  handleClick() {    
       TableAlerts();
  }
  

  
	constructor(props) {
    super(props);
  }
  render () {
    const { t, i18n } = this.props;
  
 
  
      
    return (
      <div className="containerHome">
<div className='body'>

 

            {/* <div  style={{flexDirection: "column",width:"30%",MinWidth:"30%"}}> */}
            <div className='col1' style={{width:"13%",MinWidth:"13%"}}>

              <ReactFullscreen>
              {({ ref, onRequest, onExit }) => (
                <div
                class="col" 
                  ref={ref}
                  style={{ backgroundColor: "#9fa1a6" , width:"100%", height:"80%",paddingRight: "0",paddingLeft: "0",paddingBottom: "1px",overflow:"hidden"  }}
                >
                  <Grid item  style={{height:"34px",backgroundColor:"#2f343d",color: "white",textAlign: "center",marginRight:"-4px"}}
                  >User List
<IconButton style={{position:"absolute", right:"15%", color:"white", padding:"0"}} onClick={() => { this.props.downloadTxtFile() } } aria-label="delete"> <DownloadIcon/></IconButton> {/* -- PB -- 05/04/2022 */}
                  <IconButton  style={{position:"absolute",right:"6%",color: "white",padding:"0"}} onClick={() => onRequest()} aria-label="delete"> <ZoomOutMapIcon/></IconButton>
                  <IconButton  style={{position:"absolute",right:"2%",color: "white",padding:"0"}} onClick={() => onRequest()} aria-label="delete"> <MoreVertIcon/></IconButton>
                  </Grid>
                  
                  <TableUsrs/>
                </div>
              )}
            </ReactFullscreen>
            <ResizePanel direction="n" style={{ flexGrow: '1' }}>
            <ReactFullscreen>
              {({ ref, onRequest, onExit }) => (
                <div
                class="col"
                id="channels"
                  ref={ref}
                  style={{ backgroundColor: '#9fa1a6', width:"100%", height:"50%",paddingRight: "0",paddingLeft: "0" ,paddingTop: "1px",overflow:"hidden"  }}
                >
                  <Grid item  style={{height:"34px",backgroundColor:"#2f343d",color: "white",textAlign: "center",marginRight:"-4px"}}
                  >Information Channels
                  <IconButton  style={{position:"absolute",right:"6%",color: "white",padding:"0"}} onClick={() => onRequest()} aria-label="delete"> <ZoomOutMapIcon/></IconButton>
                  <IconButton  style={{position:"absolute",right:"2%",color: "white",padding:"0"}} onClick={() => onRequest()} aria-label="delete"> <MoreVertIcon/></IconButton>                  </Grid>
                  
                  <TableInformationCh/> 
                </div>
              )}
            </ReactFullscreen>
            </ResizePanel>
            </div>

          <div className='content panel'>

          <ReactFullscreen>
              {({ ref, onRequest, onExit }) => (
                <div
                class="col" 
                  ref={ref}
                  style={{ backgroundColor: '#9fa1a6', width:"100%", height:"50%",overflow:"hidden"  }}
                >
                  <Grid item  style={{height:"30px",backgroundColor:"#2f343d",color: "white",textAlign: "center",marginRight:"-4px"}}
                  >Map

<IconButton style={{position:"absolute", right:"15%", color:"white", padding:"0"}} onClick={() => { this.props.GetAllUsr() ;  this.props.DELTPOS() ; this.props.fetchCheckpoints(); this.forceUpdate()  } } aria-label="delete"> <Delete/></IconButton> {/* -- PB - 11/11/2021 Bazo to button gia clear location */}
{/*<IconButton style={{position:"absolute", right:"20%", color:"white", padding:"0"}} onClick={() => this.props.getIds()} aria-label="delete"> <RefreshIcon/></IconButton> *} {/* -- PB - 11/11/2021 Bazo to button gia clear location */}
<IconButton  style={{position:"absolute",right:"11%",color: "white",padding:"0"}} onClick={() => this.props.fetchCheckpoints()} aria-label="delete"> <RefreshIcon/></IconButton>

                  <IconButton  style={{position:"absolute",right:"6%",color: "white",padding:"0"}} onClick={() => onRequest()} aria-label="delete"> <ZoomOutMapIcon/></IconButton>
                  <IconButton  style={{position:"absolute",right:"2%",color: "white",padding:"0"}} onClick={() => onRequest()} aria-label="delete"> <MoreVertIcon/></IconButton>
               
                  </Grid>
                  
                    
                      <MapContainer  />
                      
                </div>
              )}
            </ReactFullscreen>
            <ResizePanel direction="n" style={{ flexGrow: '1' }}>
            <ReactFullscreen>
              {({ ref, onRequest, onExit }) => (
                <div
                class="col" 
                  ref={ref}
                  style={{backgroundColor: '#9fa1a6', width:"100%", height:"100%",overflow:"hidden"  }}
                >
                  <Grid item  style={{backgroundColor: '#9fa1a6',height:"30px",backgroundColor:"#2f343d",color: "white",textAlign: "center",marginRight:"-4px"}}
                  >Files {this.props?.selectedTab===0? this.props?.selectedUser: this.props?.selectedDepartment } 
                  <IconButton  style={{position:"absolute",right:"6%",color: "white",padding:"0"}} onClick={() => onRequest()} aria-label="delete"> <ZoomOutMapIcon/></IconButton>
                  <IconButton  style={{position:"absolute",right:"2%",color: "white",padding:"0"}} onClick={() => onRequest()} aria-label="delete"> <MoreVertIcon/></IconButton>
                  </Grid>
                  
                  <TableReports/> 
                </div>
              )}
            </ReactFullscreen>
            </ResizePanel>
          </div>
          <ResizePanel direction="w" style={{ width: '30%',MinWidth:'240px' }} >
            <div className='sidebar panel'>
            

            <ReactFullscreen>
              {({ ref, onRequest, onExit, refreshList}) => (
                <div
                class="col" 
                  ref={ref}
                  style={{ backgroundColor: '#9fa1a6', width:"100%", height:"100%",overflow:"hidden"  }}
                >
                  <Grid item  style={{height:"30px",backgroundColor:"#2f343d",color: "white",textAlign: "center",marginRight:"-4px"}}
                  >Alerts                  
                 
                 
                  <IconButton  style={{position:"absolute",right:"6%",color: "white",padding:"0"}} onClick={() => onRequest()} aria-label="delete"> <ZoomOutMapIcon/></IconButton>
                  <IconButton  style={{position:"absolute",right:"2%",color: "white",padding:"0"}} onClick={() => onRequest()} aria-label="delete"> <MoreVertIcon/></IconButton>
                  </Grid>
                  
                  <TableAlerts/> 
                </div>
              )}
            </ReactFullscreen>
            <ResizePanel direction="n" style={{ flexGrow: '1' }}>
            <ReactFullscreen>
              {({ ref, onRequest, onExit }) => (
                <div
                class="col" 
                  ref={ref}
                  style={{ backgroundColor: '#9fa1a6', width:"100%", height:"100%",overflow:"hidden"  }}
                >
                  <Grid item  style={{height:"30px",backgroundColor:"#2f343d",color: "white",textAlign: "center",marginRight:"-4px"}}
                  >Messages  {this.props?.selectedTab===0? this.props?.selectedUser: this.props?.selectedDepartment } 
                  <IconButton  style={{position:"absolute",right:"6%",color: "white",padding:"0"}} onClick={() => onRequest()} aria-label="delete"> <ZoomOutMapIcon/></IconButton>
                  <IconButton  style={{position:"absolute",right:"2%",color: "white",padding:"0"}} onClick={() => onRequest()} aria-label="delete"> <MoreVertIcon/></IconButton>
                  </Grid>                  
                  <TableMessages/> 
                </div>
              )}
            </ReactFullscreen>
            </ResizePanel>
            </div>
          </ResizePanel>

          </div>
            <Grid item  style={{color: "white" , height:"25px",width:"99%",backgroundColor:"#2f343d", textAlign: "center"}}>
              © 2020 EIGHT BELLS LTD
              </Grid>
              {/* <Grid item  style={{position:"absolute",width:"99%",bottom:"10px",height:"20px",backgroundColor:"#C2CBCF",color: "white"}}>

           </Grid> */}
             </div>





            
              

    
    );
  }
  
}


const MapStateToProps = (state) => {    
  return {    

    selectedUser:state.selectedUser, // -- PB - 30/11/2021
    selectedDepartment: state.selectedDepartment, // -- PB - 30/11/2021
    selectedTab: state.selectedTab,


  };
};
const MapDispatchToProps = (dispatch) => {
return {

fetchCheckpoints: ()=> dispatch(fetchCheckpoints),
delCheckPoint: ()=> dispatch(delCheckPoint), // -- PB - 12/11/2021
downloadTxtFile: () => dispatch(downloadTxtFile), // -- PB - 05/11/2022
GetAllUsr: ()=> dispatch(GetAllUsr), 
DELTPOS: () => dispatch(DEL_POTITIONS()),
 
}
 
};
export default connect(MapStateToProps, MapDispatchToProps)(HomeMain);
