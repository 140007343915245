import {SET_SELECTED_USER,SET_CENTER_LAT,SET_CENTER_LNG, SELECTED_TAB} from '../actions/actionsUser';
const userReducer = (state = {}, {type, payload}) => {
        switch(type) {        
            case SET_SELECTED_USER :       
                return payload   
            default :    
                return state
        };
};
export default userReducer;



