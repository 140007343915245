import axios from "axios";
import qs from 'qs';
import API_URL from '../GlobalVars'



class AuthService {
  login(username, password) {
    return axios({
        method: 'post',
        url: API_URL + "/api/v1/login",
        data: {
          username: username,
          password: password
        },
        headers: {
          'content-type': 'application/json'
        }
      }).then(response => {
       // console.log(response.data.data)
        
            if (response.data.data) {
              localStorage.setItem("user", JSON.stringify(response.data.data));
            }
    
            return response.data;
          });

  
  }

  logout() {
    localStorage.removeItem("user");
  }

//   register(username, email, password) {
//     return axios.post(API_URL + "/api/Account/Register", {
//       username,
//       email,
//       password
//     });
//   }

  getCurrentUser() {
    var tmp=JSON.parse(localStorage.getItem('user'));
    if(tmp){
       return  tmp
    }
    this.redirect()
  }



  _callbacks = [];
  _nextSubscriptionId = 0;
  _user = null;
  _isAuthenticated = false;

  // By default pop ups are disabled because they don't work properly on Edge.
  // If you want to enable pop up authentication simply set this flag to false.
  _popUpDisabled = true;

  async isAuthenticated() {
      const user = await this.getCurrentUser();
      return !!user;
  }



  // We try to sign out the user in two different ways:
  // 1) We try to do a sign-out using a PopUp Window. This might fail if there is a
  //    Pop-Up blocker or the user has disabled PopUps.
  // 2) If the method above fails, we redirect the browser to the IdP to perform a traditional
  //    post logout redirect flow.



  updateState(user) {
    this._user = user;
    this._isAuthenticated = !!this._user;
    this.notifySubscribers();
}

  subscribe(callback) {
    this._callbacks.push({ callback, subscription: this._nextSubscriptionId++ });
    return this._nextSubscriptionId - 1;
}

unsubscribe(subscriptionId) {
    const subscriptionIndex = this._callbacks
        .map((element, index) => element.subscription === subscriptionId ? { found: true, index } : { found: false })
        .filter(element => element.found === true);
    if (subscriptionIndex.length !== 1) {
        throw new Error(`Found an invalid number of subscriptions ${subscriptionIndex.length}`);
    }

    this._callbacks = this._callbacks.splice(subscriptionIndex[0].index, 1);
}

notifySubscribers() {
    for (let i = 0; i < this._callbacks.length; i++) {
        const callback = this._callbacks[i].callback;
        callback();
    }
}


createArguments(state) {
    return { useReplaceToNavigate: true, data: state };
}

error(message) {
    return { status: AuthenticationResultStatus.Fail, message };
}

success(state) {
    return { status: AuthenticationResultStatus.Success, state };
}

redirect() {
    return { status: AuthenticationResultStatus.Redirect };
}



}

export default new AuthService();


export const AuthenticationResultStatus = {
  Redirect: 'redirect',
  Success: 'success',
  Fail: 'fail'
};