import {createStore, combineReducers, compose, applyMiddleware} from 'redux';



import checkpointReducer from './reducers/checkpointReducer';
import userReducer from './reducers/userReducer';
import departmentReduser from './reducers/departmentReducer';

import latReducer from './reducers/latReducer';
import lngReducer from './reducers/lngReducer';
import tabReducer from './reducers/tabReducer';
 


import thunk from 'redux-thunk';


const middleware = [thunk];


const allReducers = combineReducers({
    selectedUser: userReducer,
    checkpoints: checkpointReducer,
    selectedDepartment: departmentReduser,
    centerLat: latReducer,
    centerLng: lngReducer,
    selectedTab:tabReducer
});



const initialState = {    
    selectedUser: "",
    centerLat: 38.010849,
    centerLng: 23.735382,
    checkpoints: [], 
    selectedDepartment: "",
    selectedTab:0


};

const store = createStore(allReducers, initialState, 
    compose( applyMiddleware(...middleware))
    );
// ,window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
export default store;