import React, {  useState,useEffect  } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Paper from '@material-ui/core/Paper';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import InboxIcon from '@material-ui/icons/Inbox';
import DraftsIcon from '@material-ui/icons/Drafts';
import Typography from '@material-ui/core/Typography';
import { Route, MemoryRouter } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
 

import { Grid  } from '@material-ui/core';

import { connect } from "react-redux";
import DescriptionIcon from '@material-ui/icons/Description';
import { sayHello } from './TabUsers';
import API_URL from './GlobalVars'
import AuthService from './api-authorization/AuthorizeService'
import { Link } from "react-router-dom";

    
function ListItemLink(props) {
  

  const { icon, primary, to , userf } = props;

  const renderLink = React.useMemo(
    () => React.forwardRef((itemProps, ref) => <RouterLink to={to} ref={ref} {...itemProps} />),
    [to],
  );

  return (
    <li>
      <ListItem button component={renderLink}>
        {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
        <ListItemText primary={primary} />
      </ListItem>
    </li>
  );
}

ListItemLink.propTypes = {
  icon: PropTypes.element,
  primary: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
};

const useStyles = makeStyles({
  root: {
   backgroundColor: "#9fa1a6" ,
    height:"100%",
    width: "100%",
  },
});

 function ListRouter(props) {
//  alert(props.selectedUser);
  const [Dptlist, setDptlist] = React.useState(null);
// -- PB 16/07/2021
//-------------------------//
useEffect(() => { 
  refreshListGrp();
}, [props?.selectedDepartment])

async function refreshListGrp(){
  
  var url=API_URL+'/api/v1/groups.files?roomName='+props.selectedDepartment;
  if(AuthService.getCurrentUser()){
              var user=AuthService.getCurrentUser()
              var token=user.authToken.toString()
              var userId=user.userId.toString()
      
              fetch(url, {
                  method: 'GET', // *GET, POST, PUT, DELETE, etc.
                  // mode: 'cors', // no-cors, *cors, same-origin
                  // cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                  // credentials: 'same-origin', // include, *same-origin, omit
                  headers: {
                      'Content-Type': 'application/json',
                      'X-Auth-Token': token,
                      'X-User-Id': userId,
                  
                  // 'Content-Type': 'application/x-www-form-urlencoded',
                  },
                  redirect: 'follow', // manual, *follow, error
                  referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
                  // body: JSON.stringify(data) // body data type must match "Content-Type" header
              })
              .then(res => res.json())    
              .then(res =>{
                setDptlist(res.files)
                
              })
  };  
      
}





  // -- PB 15/07/2021
//-------------------------//
  useEffect(() => { 
    refreshList();
  }, [props?.selectedUser])

  async function refreshList(){
    
    



    var url=API_URL+'/api/v1/im.files?username='+props.selectedUser;
    if(AuthService.getCurrentUser()){
                var user=AuthService.getCurrentUser()
                var token=user.authToken.toString()
                var userId=user.userId.toString()
        
                fetch(url, {
                    method: 'GET', // *GET, POST, PUT, DELETE, etc.
                    // mode: 'cors', // no-cors, *cors, same-origin
                    // cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                    // credentials: 'same-origin', // include, *same-origin, omit
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Auth-Token': token,
                        'X-User-Id': userId,
                    
                    // 'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    redirect: 'follow', // manual, *follow, error
                    referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
                    // body: JSON.stringify(data) // body data type must match "Content-Type" header
                })
                .then(res => res.json())    
                .then(res =>{
                  setDptlist(res.files)
                  
                })
    };  
        
  }
  // -- PB 15/07/2021
  const classes = useStyles();
  const [greet, setGreeet] = useState("");
  const user = AuthService.getCurrentUser();
 

  return (
    
    
    <Grid container spacing={1}>
        
    <Grid item xs style={{maxHeight: "90%", overflow: 'hidden'}}>

     
    <List style={{ backgroundColor: "#9fa1a6" ,height:"100%", overflow: 'auto'}} >
         {Dptlist?.map(files => (  
     files.type !='image/jpeg'?
     <List aria-label="main mailbox folders"> 
     <ListItem button component="a" href={files.url}>     
      { files?.user?.username.includes(props?.selectedUser) ? <DescriptionIcon style={{color:"#21242b"}} />  :  <DescriptionIcon style={{color:"white"}} />  } {/* -- PB - 24/11/2021 Ksexorizei ta files */}
       <ListItemText primary={files.name}/>            
            </ListItem>
            </List>:null
            ))}
        </List>
         
    </Grid>
  </Grid>

);
}



const MapStateToProps = (state) => {    
  return {    
 
    selectedUser:state.selectedUser,
    selectedDepartment: state.selectedDepartment,
  };
};
const MapDispatchToProps = (dispatch) => {
  return {
   
  }
};
export default connect(MapStateToProps, MapDispatchToProps)(ListRouter);

