import { SELECTED_TAB} from '../actions/actionsUser';
const tabReducer = (state = {}, {type, payload}) => {
        switch(type) {        
            case SELECTED_TAB:
                return payload
            default :    
                return state
        };
};
export default tabReducer;



