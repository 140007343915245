import {SET_SELECTED_DEPARTMENT} from '../actions/actionsDepartments';
import { SELECTED_TAB } from '../actions/actionsUser';
const userReducer = (state = {}, {type, payload}) => {
        switch(type) {        
            case SET_SELECTED_DEPARTMENT :       
                return payload   
            default :    
                return state
        };
};
export default userReducer;