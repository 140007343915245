import React, {  useState,useEffect  } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Paper from '@material-ui/core/Paper';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import InboxIcon from '@material-ui/icons/Inbox';
import DraftsIcon from '@material-ui/icons/Drafts';
import Typography from '@material-ui/core/Typography';
import { Route, MemoryRouter } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';

import { Grid  } from '@material-ui/core';

import { connect } from "react-redux";
import DescriptionIcon from '@material-ui/icons/Description';
import { sayHello } from './TabUsers';
import API_URL from './GlobalVars'
import AuthService from './api-authorization/AuthorizeService'
import { Link } from "react-router-dom";
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import Avatar from '@material-ui/core/Avatar';
import { SignalCellularNullRounded } from '@material-ui/icons';

import TextField from '@material-ui/core/TextField';
import SendIcon from '@material-ui/icons/Send';
import IconButton from '@material-ui/core/IconButton';

import {withStyles} from "@material-ui/core/styles" // -- PB - 24/11/2021 

var dateFormat = require('dateformat');

const WhiteTextTypography = withStyles({ // -- PB - 24/11/2021 
  root: {
    color: "#FFFFFF"
  }
})(Typography); 

const DefaultTextTypography = withStyles({ // -- PB - 24/11/2021 
  root: {
    color: "#20232a",
    fontWeight: 'bold'
  }
})(Typography); 

const MessageTextTypography = withStyles({ // -- PB - 24/11/2021 
  root: {
    color: "#20232a"
  }
})(Typography); 


function ListItemLink(props) {
  

  const { icon, primary, to , userf } = props;

  const renderLink = React.useMemo(
    () => React.forwardRef((itemProps, ref) => <RouterLink to={to} ref={ref} {...itemProps} />),
    [to],
  );

  return (
    <li>
      <ListItem button component={renderLink}>
        {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
        <ListItemText primary={primary} />
      </ListItem>
    </li>
  );
}

ListItemLink.propTypes = {
  icon: PropTypes.element,
  primary: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
};

const useStyles = makeStyles({
  root: {
   backgroundColor: "#9fa1a6" ,
    height:"100%",
    width: "100%",
  },
});

 function ListRouter(props) {
//  alert(props.selectedUser);
  const [Dptlist, setDptlist] = React.useState(null);
  const [sendmsgText, setSendmsgText] = React.useState('');

// -- PB 16/07/2021
//-------------------------//
useEffect(() => { 
  refreshListGrp();
}, [props?.selectedDepartment])

async function refreshListGrp(){
  
  var url=API_URL+'/api/v1/groups.messages?roomName='+props.selectedDepartment+"&count=10000";
  if(AuthService.getCurrentUser()){
              var user=AuthService.getCurrentUser()
              var token=user.authToken.toString()
              var userId=user.userId.toString()
      
              fetch(url, {
                  method: 'GET', // *GET, POST, PUT, DELETE, etc.
                  // mode: 'cors', // no-cors, *cors, same-origin
                  // cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                  // credentials: 'same-origin', // include, *same-origin, omit
                  headers: {
                      'Content-Type': 'application/json',
                      'X-Auth-Token': token,
                      'X-User-Id': userId,
                  
                  // 'Content-Type': 'application/x-www-form-urlencoded',
                  },
                  redirect: 'follow', // manual, *follow, error
                  referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
                  // body: JSON.stringify(data) // body data type must match "Content-Type" header
              })
              .then(res => res.json())    
              .then(res =>{
                setDptlist(res.messages)
                
              })
  };  
      
}
async function sendMsg() {
  var url=API_URL+'/api/v1/chat.postMessage';
  if(AuthService.getCurrentUser()){
    var user=AuthService.getCurrentUser()
    var token=user.authToken.toString()
    var userId=user.userId.toString()


let data={
  text: sendmsgText,
  roomId: "8McZDpTBcPu3M54FLB6Xjo9Rxo9jAAdkqu"
}

    fetch(url, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        // mode: 'cors', // no-cors, *cors, same-origin
        // cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        // credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json',
            'X-Auth-Token': token,
            'X-User-Id': userId,
        
        // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(data) 
    })
    .then(res =>{
     
       // refreshListGrp();

       // refreshList();
    
    })
  };  

 

}

// function changeMsg(e) {

//   setSendmsgText(e.target.value)

// }




  // -- PB 15/07/2021
//-------------------------//
  useEffect(() => { 
    refreshList();
  }, [props?.selectedUser])

  async function refreshList(){
    
    var url=API_URL+'/api/v1/im.messages?username='+props.selectedUser+"&count=10000";
    if(AuthService.getCurrentUser()){
                var user=AuthService.getCurrentUser()
                var token=user.authToken.toString()
                var userId=user.userId.toString()
        
                fetch(url, {
                    method: 'GET', // *GET, POST, PUT, DELETE, etc.
                    // mode: 'cors', // no-cors, *cors, same-origin
                    // cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                    // credentials: 'same-origin', // include, *same-origin, omit
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Auth-Token': token,
                        'X-User-Id': userId,
                    
                    // 'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    redirect: 'follow', // manual, *follow, error
                    referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
                    // body: JSON.stringify(data) // body data type must match "Content-Type" header
                })
                .then(res => res.json())    
                .then(res =>{
                  setDptlist(res.messages)
                  
                })
    };  
        
  }
  // -- PB 15/07/2021
  const classes = useStyles();
  const [greet, setGreeet] = useState("");

  return (
    
    <Grid container spacing={1}>
        
    <Grid item xs style={{maxHeight: "90%", overflow: 'hidden'}}>

    

    <List style={{ backgroundColor: "#9fa1a6" ,height:"87%", overflow: 'auto'}} >


         {Dptlist?.map(messages => (  
            messages.t !=='au' && messages.t!=='ru' && 
            messages.t !=='uj' && messages.t !== 'subscription-role-added' && 
            messages.t !== 'subscription-role-removed' && 
            messages.t !== 'user-muted' && messages.t !== 'user-unmuted' && 
            messages.t !== 'message_pinned' && messages.t !=='room_changed_description' && 
            !messages.file && // -- PB - 24/11/2021 Na mhn emfanizei ta files sta messages
            messages.t !== 'room_changed_announcement' && 

            messages.t !== 'room_changed_topic'?
            
     <List aria-label="main mailbox folders">

     <Divider variant="inset" component="li" />
     {props.selectedUser.includes(messages?.u?.username) ? 
    <ListItem alignItems="flex-start">
    <ListItemAvatar>
          <AccountCircleIcon/>
    </ListItemAvatar>
  
    <ListItemText
      primary={
        messages.t!== "jitsi_call_started"?  
        <DefaultTextTypography>
        {messages?.u?.name}
       </DefaultTextTypography>   :"Call"}
      secondary={
        <React.Fragment> 
          {/* -- PB - 24/11/2021 */}
        
      
          <MessageTextTypography>            
       {messages.msg}
       </MessageTextTypography>
          {dateFormat(messages?.ts, "dddd, mmmm dS, yyyy, h:MM:ss TT")}
        </React.Fragment>
      }
    />
   
  </ListItem>
      :
      <ListItem alignItems="flex-start">
      <ListItemAvatar>
            <AccountCircleIcon style={{fill:"white"}}  />
      </ListItemAvatar>
    
      <ListItemText
        primary={
          messages.t!== "jitsi_call_started"?  
          <WhiteTextTypography>
          {messages?.u?.name}
         </WhiteTextTypography>   :"Call"}
        secondary={
          <React.Fragment> 
            {/* -- PB - 24/11/2021 */}
          
        
            <MessageTextTypography>            
         {messages.msg}
         </MessageTextTypography>
            {dateFormat(messages?.ts, "dddd, mmmm dS, yyyy, h:MM:ss TT")}
          </React.Fragment>
        }
      />
     
    </ListItem>
 }




           

            </List>:null
            ))}
        </List>
        <form className={classes.root} noValidate autoComplete="off">
   <div class="row">
     <div class="col">
     {/* <TextField  id="outlined-basic" label="Message" variant="outlined" /> */}
{/*
     <TextField  id="outlined-basic" label="Message" variant="outlined"
                type="text"
                value={sendmsgText}
              //  onChange={changeMsg()}
               // onChange={setSendmsgText(this.target.value)}
                // onChange={(_, value) => {
                //   setSendmsgText(value);
                // }}
                onChange ={(e) => setSendmsgText(e.target.value)}
                // validations={[required]}
              />
     </div>
     <div class="col">
     <IconButton edge="end" aria-label="send">
        <SendIcon  
         onClick={() => {
                  sendMsg();
          }}
  />
                    </IconButton>
        */} {/*  --PB - 01/09/2021 Bgazo ta eikonidia gia na steilei mhnuma*/}
       </div>
   </div>
 </form>
    </Grid>
  </Grid>

);
}

const MapStateToProps = (state) => {    
  return {    
 
    selectedUser:state.selectedUser,
    selectedDepartment: state.selectedDepartment,
    selectedTab:state.selectedTab
  };
};
const MapDispatchToProps = (dispatch) => {
  return {
   
  }
};
export default connect(MapStateToProps, MapDispatchToProps)(ListRouter);

