import React, { useState, useEffect } from 'react';
import { forwardRef } from 'react';
import { Grid  } from '@material-ui/core';

import { connect } from "react-redux";



import MaterialTable from "material-table";


import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import LinkIcon from '@material-ui/icons/Link';


import axios from 'axios'
import Alert from '@material-ui/lab/Alert';
import API_URL from './GlobalVars'
import AuthService from '../components/api-authorization/AuthorizeService'


var host = API_URL

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),

};

const token='Bearer '+AuthService.getCurrentUser()?.authToken.toString()

const api = axios.create({
  baseURL: host,
  headers: {
    'Authorization': token
  }
})


function validateEmail(email){
  const re = /^((?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\]))$/;
  return re.test(String(email).toLowerCase());
}

function TableProjects(props) {

  const [open, setOpen] = React.useState(false);
  const [AddFirstResponderName, setAddFirstResponderName] = React.useState(null);
  const [AddFirstResponderEmail, setAddFirstResponderEmail] = React.useState(null);
  const [AddFirstResponderPassword, setAddFirstResponderPassword] = React.useState(null);


  const handleInputChangeAddFirstResponderName = e => {
    setAddFirstResponderName(e.target.value);
};

const handleInputChangeAddFirstResponderEmail = e => {
  setAddFirstResponderEmail(e.target.value);
};

const handleInputChangeAddFirstResponderPassword = e => {
  setAddFirstResponderPassword(e.target.value);
};

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setAddFirstResponderName(null);
    setAddFirstResponderEmail(null);
    setAddFirstResponderPassword(null);

  };

  const handleCloseAddFirstResponder = () => {
   // alert(AddFirstResponderName+','+AddFirstResponderEmail+','+AddFirstResponderPassword)

    let errorList = []
    if(AddFirstResponderName === undefined){
      errorList.push("Please enter FirstRespondername")
    }
    if(AddFirstResponderPassword === undefined){
      errorList.push("Please enter password")
    }
    if(AddFirstResponderEmail === undefined || validateEmail(AddFirstResponderEmail) === false){
      errorList.push("Please enter a valid email")
    }
    var data={
      FirstRespondername:AddFirstResponderName,
        Email:AddFirstResponderEmail,
        Password:AddFirstResponderPassword,
    }
   // alert(data.FirstRespondername)

    if(errorList.length < 1){ //no error
      // api.post("/api/Authenticate/register", data)
      // .then(res => {
      //   setErrorMessages([])
      //   setIserror(false)
      // })
      // .catch(error => {
      //   setErrorMessages(["Cannot add data. Server error!"])
      //   setIserror(true)
      // })
      api({
        method: 'post',
        url:  "/api/Projects/",
        data: {
          FirstRespondername: AddFirstResponderName,
          password: AddFirstResponderPassword,
          email:AddFirstResponderEmail,
        },
        headers: {
          'content-type': 'application/json'
        }
      }).then(response => {
        setErrorMessages([])
         setIserror(false)
        // refreshProjects()
            // if (response.data.token) {
            //   localStorage.setItem("FirstResponder", JSON.stringify(response.data));
            // }
    
            // return response.data;
          })  .catch(error => {
              setErrorMessages(["Cannot add data. Server error!"])
              setIserror(true)
             });
    }else{
      setErrorMessages(errorList)
      setIserror(true)
    }



    setOpen(false);
  };

  const [openLinkFR, setOpenLinkFR] = React.useState(false);

  const handleClickOpenLinkFR = () => {
    setOpenLinkFR(true);
  };

  const handleCloseLinkFR = () => {
    setOpenLinkFR(false);
  };

  var columns = [
    {title: "id", field: "_id", hidden: true},
    {title: "Name", field: "username"},
    {title: "Status", field: "status"},

    {title: 'Active', field: 'active', type: 'boolean',initialEditValue: false},
//       render: rowData => 
//       <Checkbox
//   value="active"
//   checked={rowData.active}
//   inputProps={{ 'aria-label': 'Active' }}
// />
      // <img src={rowData.url} style={{width: 50, borderRadius: '50%'}}/>
   // }


  ]
  const [data, setData] = useState([]); //table data

  //for error handling
  const [iserror, setIserror] = useState(false)
  const [errorMessages, setErrorMessages] = useState([])

  // function refreshProjects(){
  //   api.get("/api/Projects/")
  //       .then(res => {           
  //        // alert(res)    
  //           setData(res.data)
  //        })
  //        .catch(error=>{
  //            console.log("Error")
  //        })
  // }

  // useEffect(() => { 
  //   refreshProjects();
  // }, [])

  // const handleRowUpdate = (newData, oldData, resolve) => {
  //   //validation
  //   let errorList = []
   
  //   if(newData.description === ""){
  //     errorList.push("Please enter description")
  //   }
  //   if(newData.active === ""){
  //     errorList.push("Please enter active state")
  //   }
   

  //   if(errorList.length < 1){
  //     api.put("/api/Projects/"+newData.id, newData)
  //     .then(res => {
  //       // const dataUpdate = [...data];
  //       // const index = oldData.tableData.id;
  //       // dataUpdate[index] = newData;
  //       // setData([...dataUpdate]);
  //       resolve()
  //       props.fetchProjects()

  //       setIserror(false)
  //       setErrorMessages([])
  //     })
  //     .catch(error => {
  //       setErrorMessages(["Update failed! Server error"])
  //       setIserror(true)
  //       resolve()
        
  //     })
  //   }else{
  //     setErrorMessages(errorList)
  //     setIserror(true)
  //     resolve()

  //   }
    
  // }

  // const handleRowAdd = (newData, resolve) => {
  //   //validation
  //   let errorList = []
  //   if(newData.description === undefined){
  //     errorList.push("Please enter description")
  //   }
  //   // if(newData.active === undefined){
  //   //   errorList.push("Please enter active state")
  //   // }
  

  //   if(errorList.length < 1){ //no error
      
  //     console.log(newData)
  //     api.post("/api/Projects/", newData)
  //     .then(res => {
  //       // let dataToAdd = [...data];
  //       // dataToAdd.push(newData);
  //       // setData(dataToAdd);
  //       resolve()
  //       props.fetchProjects()
  //       setErrorMessages([])
  //       setIserror(false)
  //     })
  //     .catch(error => {
  //       setErrorMessages(["Cannot add data. Server error!"])
  //       setIserror(true)
  //       resolve()
  //     })
  //   }else{
  //     setErrorMessages(errorList)
  //     setIserror(true)
  //     resolve()
  //   }

    
  // }

  // const handleRowDelete = (oldData, resolve) => {
  //  // alert(oldData.FirstResponderName)
  //   // axios({
  //   //   method: 'delete',
  //   //   url: API_URL + "/api/Projects/",
  //   //   data: {
  //   //     FirstResponderName: oldData.FirstResponderName,
  //   //   },
  //   //   headers: {
  //   //     'content-type': 'application/json'
  //   //   }
  //   // }).then(response => {
  //   //   setErrorMessages([])
  //   //    setIserror(false)
  //   //    refreshProjects()
  //   //    resolve()
  //   //       // if (response.data.token) {
  //   //       //   localStorage.setItem("FirstResponder", JSON.stringify(response.data));
  //   //       // }
  
  //   //       // return response.data;
  //   //     })  .catch(error => {
  //   //         setErrorMessages(["Delete failed! Server error"])
  //   //         setIserror(true)
  //   //         resolve()
  //   //        });

  //   api.delete("/api/Projects/"+oldData.id)
  //     .then(res => {
  //       props.DeleteProject(oldData.id);
  //       resolve()
  //     })
  //     .catch(error => {
  //       setErrorMessages(["Delete failed! Server error"])
  //       setIserror(true)
  //       resolve()
  //     })
  // }


  return (
 
      
      <Grid container spacing={1}>
        
          <Grid item >
          <Grid item  style={{height:"30px",backgroundColor:"grey"}}></Grid>
          <div>
            {iserror && 
              <Alert severity="error">
                  {errorMessages.map((msg, i) => {
                      return <div key={i}>{msg}</div>
                  })}
              </Alert>
            }       
          </div>
            <MaterialTable
              title="Users"
              columns={columns}
              data={props.projects}
              icons={tableIcons}
             
              editable={{
                // onRowUpdate: (newData, oldData) =>
                //   new Promise((resolve) => {
                //       handleRowUpdate(newData, oldData, resolve);
                      
                //   }),
                // onRowAdd: (newData) =>
                //   new Promise((resolve) => {
                //     handleRowAdd(newData, resolve)
                //   }),
                // onRowDelete: (oldData) =>
                //   new Promise((resolve) => {
                //     handleRowDelete(oldData, resolve)
                //   }),
              }}
            />
          </Grid>
         
        </Grid>

        

     

     

  );
}

const MapStateToProps = (state) => {    
    return {    
      projects:state.checkpoints,
    };
  };
  const MapDispatchToProps = (dispatch) => {
    return {
     
    }
  };
  export default connect(MapStateToProps, MapDispatchToProps)(TableProjects);