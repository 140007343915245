import {SET_CENTER_LNG} from '../actions/actionsUser';



const lngReducer = (state = {}, {type, payload}) => {
    switch(type) {        
        case SET_CENTER_LNG :       
            return payload   

        default :    
            return state
    };
};
export default lngReducer;