import {FETCH_CHECKPOINTS} from '../actions/actionsCheckpoints';
import {DEL_POTITIONS} from '../actions/actionsCheckpoints';
const checkpointReducer = (state = {}, {type, payload}) => {
        switch(type) {        
            case FETCH_CHECKPOINTS :       
                return payload     

            default :    
                return state
        };
};
export default checkpointReducer;