import React, { Component, useCallback } from 'react';
import { GoogleMap, LoadScript,DrawingManager} from '@react-google-maps/api';
import axios from 'axios';


import  CheckpointMarker from './CheckpointMarker';

import AddLocationIcon from '@material-ui/icons/AddLocation';
import CategoryIcon from '@material-ui/icons/Category';

import { Grid,Dialog,DialogTitle,DialogContent,DialogContentText,DialogActions ,Button ,TextField  } from '@material-ui/core';



import Select from 'react-select'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


import { connect } from "react-redux";

import { Alert } from 'bootstrap';


import API_URL from './../GlobalVars'
import {SETMAPINIT} from './../../store/actions/actionsUser'









var counter=1;

var host = API_URL

const containerStyle = {
  width: '100%',
  height: '100%'
};





 
class MapContainer extends Component {
  // -- PB - 24/11/2021 @
  getGeoInfo = () => { 
    axios.get('https://ipapi.co/json/').then((response) => {
        let data = response.data;
        this.setState({
            latOp: data.latitude,
            longOp: data.longitude,
        });
 const center = {
  lat:data.latitude,// 38.010849,
  lng: data.longitude //23.735382
  
};

    }).catch((error) => {
        console.log(error);
    });
};
  componentDidMount() {
   this.props.SETMAPINIT()
  }
    // -- PB - 24/11/2021 @
    
  constructor(props) {
    super(props);

  }
  state = {
 
    prvLat:this.props.latOp, //38.010849,
    prvLng:this.props.lngOp //23.735382
  }
 
  
  render(){
  //  console.log(this.state.latOp);
   // console.log(this.state.longOp);
    return (

      <LoadScript
        googleMapsApiKey="AIzaSyCABl8pGcuMyXNHyZU49bwLj3Ydvc2IQNk"
        libraries={["drawing"]}
        
      >

        <GoogleMap
          mapContainerStyle={containerStyle}
          center={{
            lat:   this.props.centerLat,  // this.state.latOp, // -- PB - 24/11/2021  
            lng:   this.props.centerLng,  // this.state.longOp, // -- PB - 24/11/2021  
          }}
        //  onClick={this.state.AddMarkerMode ? this.addMarker.bind(this)  :null}

          zoom={11} // -- PB - 24/11/2021  {12.5}
          
         // onLoad={this.onLoadmap}
        >
          
          <CheckpointMarker   />
      
        </GoogleMap>
      </LoadScript>
    );
  }

}
 
const MapStateToProps = (state) => {    
  return {    
    centerLat: state.centerLat,
    centerLng: state.centerLng,


  };
};
const MapDispatchToProps = (dispatch) => {
return {

  SETMAPINIT: () => dispatch(SETMAPINIT())
 
  

}
};
export default connect(MapStateToProps, MapDispatchToProps)(MapContainer);



//export default connect(MapStateToProps, null, null, {forwardRef: true})(MapContainer);
